'use strict';

import * as _tokens from './tokens';

import * as _parser from './parser';

import * as _renderer from './renderer';

/**
 * Remarkable plugin that recognizes callout syntax in markdown and renders
 * it in a dedicated paragraph.
 *
 * Example syntax:
 *
 *    ::::1 步驟一
 *    設置 > 狀態欄與通知 > 開啟在鎖屏顯示、亮屏通知
 *    ::::
 *
 * @todo Add opts to customize rendering.
 */
const plugin = (options) => (md, opts) => {
  md.block.ruler.before('code', _tokens.TOKENS.CARD, _parser.parser, opts);
  md.renderer.rules[_tokens.TOKENS.CARD_OPEN] = (0, _renderer.openRenderer)();
  md.renderer.rules[_tokens.TOKENS.CARD_CLOSE] = (0, _renderer.closeRenderer)();
};

export default plugin;
