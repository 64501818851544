import PropTypes from 'prop-types';
import React from 'react';
import { isDarkMode } from '../../params';

function HorizontalRule(props) {
  return (
    <div
      className={`HorizontalRule ${props.className}`}
      style={{
        borderBottom: isDarkMode ? '1px solid #666' : props.borderBottom,
      }}
    />
  );
}

HorizontalRule.propTypes = {
  borderBottom: PropTypes.string,
  className: PropTypes.string,
};

HorizontalRule.defaultProps = {
  borderBottom: '1px solid rgba(165, 165, 165, 0.8)',
  className: '',
};

export default HorizontalRule;
