'use strict';

import * as _tokens from './tokens';

/**
 * Remarkable block parser that recognizes callouts.
 * @todo Add options.
 */
export const parser = (state, startLine, endLine, silent) => {
  let pos = state.bMarks[startLine] + state.blkIndent;
  const max = state.eMarks[startLine]; // Not enough chars or ending line with `:::`.

  if (pos + 3 >= max) return false;
  const marker = state.src.charCodeAt(pos); // Wrong marker

  if (marker !== 0x3d /* ':' */) return false;
  let mem = pos;
  pos = state.skipChars(pos, marker); // We need exactly 4 `:`

  if (pos - mem !== 3) return false;
  const line = state.src.slice(pos, max).trim();
  let sep = line.indexOf(' ');
  if (sep === -1) sep = line.length;
  const id = line.slice(0, sep);
  const title = line.slice(sep);
  if (id === '') return false;
  if (silent) return true; // Scan for marker ending

  let nextLine = startLine;
  let hasEnding = false;

  while (nextLine < endLine) {
    nextLine++;
    if (nextLine >= endLine) break;
    const nextPos = state.bMarks[nextLine] + state.tShift[nextLine];
    const nextMax = state.eMarks[nextLine];
    if (state.src.charCodeAt(nextPos) !== marker) continue;
    const nextLineText = state.src.slice(nextPos, nextMax).trim();

    if (nextLineText === '===') {
      hasEnding = true;
      break;
    }
  } // Ensure nested parsing stops at delimiting block

  const oldMax = state.lineMax;
  state.lineMax = nextLine + (hasEnding ? -1 : 0);
  const oldParentType = state.parentType;
  state.parentType = 'card';
  let lines; // Let register token and progress

  state.tokens.push({
    type: _tokens.TOKENS.CARD_OPEN,
    level: state.level,
    lines: (lines = [startLine, 0]),
    id,
    title,
  });
  state.parser.tokenize(state, startLine + 1, nextLine);
  state.tokens.push({
    type: _tokens.TOKENS.CARD_CLOSE,
    level: state.level,
  }); // Revert

  lines[1] = nextLine;
  state.line = nextLine + (hasEnding ? 1 : 0);
  state.lineMax = oldMax;
  state.parentType = oldParentType;
  return true;
};
