import {
  MdChevronLeft as ChevronLeft,
  MdMoreHoriz as MoreHoriz,
} from 'react-icons/md';

import FitText from '@plutonium-js/react-fit-text';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { isDarkMode } from '../../params';

function Navbar(props) {
  const { currentProjectLanguage } = useSelector((state) => ({
    ...state.projectReducer,
  }));

  const currentLangCode = currentProjectLanguage?.language?.code || 'en';
  const isAr = currentLangCode === 'ar';

  return (
    <div
      className='Navbar'
      style={{
        backgroundColor: isDarkMode ? '#191E3F' : props.color || '#000',
      }}
    >
      <div className='nav-back'>
        {props.showNavButton && (
          <ChevronLeft
            onClick={() => {
              props.navigate();
            }}
          />
        )}
      </div>
      <FitText
        className='title'
        {...(isAr && { style: { direction: 'rtl' } })}
        maxSize={20}
      >
        {props.title}
      </FitText>
      <div className='more'>
        {props.showMoreButton &&
          (props.disableMoreButton ? (
            <MoreHoriz className='no-cursor' />
          ) : (
            <>
              <MoreHoriz
                onClick={() => {
                  props.moreOnClick();
                }}
              />
            </>
          ))}
      </div>
    </div>
  );
}

Navbar.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string,
  navigate: PropTypes.func,
  showNavButton: PropTypes.bool,
  showMoreButton: PropTypes.bool,
  disableMoreButton: PropTypes.bool,
  moreOnClick: PropTypes.func,
};

Navbar.defaultProps = {
  color: '#000',
  title: '',
  navigate: () => {},
  showNavButton: true,
  showMoreButton: true,
  disableMoreButton: false,
  moreOnClick: () => {},
};

export default Navbar;
