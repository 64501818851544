'use strict';

/**
 * Remarkable card renderer.
 */
export function openRenderer() {
  return (tokens, idx, options, env) => {
    const token = tokens[idx];
    return `
    <div class="card">
      <div class="card-heading">
        <div class="index">${token.id}</div>
        <div class="text">
          ${token.title}
        </div>
      </div>
      <div class="card-content">
    `;
  };
}
/**
 * Callout closing tag renderer
 */

export function closeRenderer() {
  return (tokens, idx, options, env) => {
    return `</div></div>`;
  };
}
