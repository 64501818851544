import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { isDarkMode } from '../params';
import HorizontalRule from './layout/HorizontalRule';

function ArticleContainer(props) {
  const isAr = props.lang === 'ar';
  return (
    <div className={`ArticleContainer${isDarkMode ? ' dark' : ''}`}>
      <div
        className={clsx('title', props.lang)}
        style={{
          color: isDarkMode ? '#FFF' : props.color || '#333333',
          ...(isAr && { direction: 'rtl', textAlign: 'left' }),
        }}
      >
        {props.title}
      </div>
      <HorizontalRule />
      <div
        {...(isAr && { style: { direction: 'rtl', textAlign: 'left' } })}
        className={clsx('body', props.lang)}
        dangerouslySetInnerHTML={{ __html: props.body }}
      />
    </div>
  );
}

ArticleContainer.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  lang: PropTypes.string.isRequired,
};

export default ArticleContainer;
